import moment from "moment";
import Vue from "vue";


const sumWorksheetWeight = async (worksheet) => {
    if (worksheet && worksheet.entries && worksheet.entries.length > 0) {
        const deductibleWeight = isNaN(worksheet.deductibleWeight) ? 0 : worksheet.deductibleWeight;
        const result = await worksheet.entries.reduce(
            (sum, a) => {
                const netWeight = isNaN(a.netWeight) ? 0 : a.netWeight;
                const grossWeight = isNaN(a.grossWeight) ? 0 : a.grossWeight;
                const adjustmentWeight = isNaN(a.netAdjustmentWeight) ? 0 : a.netAdjustmentWeight;
                sum.deductibleWeight += deductibleWeight;
                sum.grossWeight += grossWeight;
                const netAdjustWeight = netWeight + adjustmentWeight;
                sum.netWeight += netAdjustWeight;
                sum.count++;

                if (netAdjustWeight < sum.minWeight || sum.minWeight == null) {
                    sum.minWeight = netAdjustWeight;
                }
                if (netAdjustWeight > sum.maxWeight) {
                    sum.maxWeight = netAdjustWeight;
                }
                if (moment(a.createTime).isBefore(moment(sum.minDate)) || sum.minDate == null) {
                    sum.minDate = a.createTime;
                }
                if (moment(a.createTime).isAfter(moment(sum.maxDate)) || sum.maxDate == null) {
                    sum.maxDate = a.createTime;
                }

                return sum;
            },
            { grossWeight: 0, netWeight: 0, deductibleWeight: 0, count: 0, minWeight: null, maxWeight: 0, minDate: null, maxDate: null }
        )

        result.netWeight += worksheet.netAdjustmentWeight;
        worksheet.totalNetWeight = result.netWeight;

        return result;
    }
    else {
        return { grossWeight: 0, netWeight: 0, deductibleWeight: 0, count: 0, minWeight: 0, maxWeight: 0, minDate: null, maxDate: null }
    }
}


export {
    sumWorksheetWeight
}